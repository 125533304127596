var md = new MobileDetect(window.navigator.userAgent);
var ua = detect.parse(navigator.userAgent);
// console.log("mobile");
// console.log(md.mobile()); // 'Sony'
// console.log(md.phone()); // 'Sony'
// console.log(md.tablet()); // null
// console.log(md.userAgent()); // 'Safari'
// console.log(md.os()); // 'AndroidOS'
// console.log(md.is("iPhone")); // false
// console.log(md.is("bot")); // false
// console.log(md.version("Webkit")); // 534.3
// console.log(md.versionStr("Build")); // '4.1.A.0.562'
// console.log(md.match("playstation|xbox")); // false

// console.log("detect");
// console.log(ua.browser);
// console.log(ua.device);
// console.log(ua.os);
//test 2
var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;


var scrollI = document.getElementById("scrollIcon");
var ismobile = false;
var openMega = null;
var currentPage = 1;

if (md.mobile())
    ismobile = true;

// Define the Function targetBlank()
function targetBlank() {
    // remove subdomain of current site's url and setup regex
    var internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    var a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (var i = 0; i < a.length; i++) {
        var href = a[i].host; // set the host of each link
        if ((!internal.test(href) && !/^javascript/.test(a[i].getAttribute("href"))) || /\.pdf$/.test(a[i].getAttribute("href"))) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}

(function () {
    "use strict";

    document.querySelectorAll('[data-toggle="collapse"]').forEach((elem) => {
        var target = elem.getAttribute('data-target');
        elem.addEventListener("click", function (event) {
            var el = document.querySelector(target);
            el.className = (el.className + " show").replace(/ show show/, "");
            elem.className = (elem.className + " show").replace(/ show show/, "");
        });

    });

    // Run the function targetBlank()
    targetBlank();
    if (document.querySelectorAll(".scroll-top").length > 0) {
        document.querySelectorAll('.scroll-top')[0].addEventListener("click", function (event) {
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': 0
            });
        });
    }


    var ph = 4000;
    document.querySelectorAll('.parallax-scene').forEach((elem) => {
        const modifier = elem.getAttribute('data-modifier');

        let clientRect = elem.getBoundingClientRect();
        let start = clientRect.top - y;
        const to = start + ph;
        if (start < 0)
            start = 0;
        console.log("start " + start);
        let modifierR = modifier * (x / 1000);
        basicScroll.create({
            elem: elem,
            from: start,
            to: to,
            direct: true,
            props: {
                '--translateY': {
                    from: '0',
                    to: `${3 * modifierR}px`
                }
            }
        }).start()
    });

    if (document.querySelectorAll(".wechsler").length > 0) {
        const grid = document.querySelectorAll('div.grid')[0];
        const list = document.querySelectorAll('div.table-listview')[0];
        const gridb = document.querySelectorAll('.wechsler .bgrid')[0]
        const listb = document.querySelectorAll('.wechsler .blist')[0]
        listb.addEventListener("click", function (event) {
            list.classList.add("show");
            grid.classList.remove("show");
            listb.classList.add("active");
            gridb.classList.remove("active");
        });
        gridb.addEventListener("click", function (event) {
            grid.classList.add("show");
            list.classList.remove("show");
            gridb.classList.add("active");
            listb.classList.remove("active");
        });
    }

    document.querySelectorAll('.questions li h5:not(.no-open)').forEach((btn) => {
        var db = btn;
        db.onclick = function () {
            if (db.closest('.open-text').classList.contains("open")) {
                db.closest('.open-text').classList.remove("open");
            } else {
                db.closest('.open-text').classList.add("open");
            }
        };
    });
    document.querySelectorAll('.questions li h4:not(.no-open)').forEach((btn) => {
        var db = btn;
        db.onclick = function () {
            if (db.closest('.open-text').classList.contains("open")) {
                db.closest('.open-text').classList.remove("open");
            } else {
                db.closest('.open-text').classList.add("open");
            }
        };
    });

    if (document.querySelectorAll("button.navbar-toggler").length > 0) {

        anime({
            targets: '.navbar-nav li',
            translateX: 200,
            opacity: 0,
            delay: anime.stagger(20)
        });
        // Mobile toggler
        document.querySelectorAll("button.navbar-toggler")[0].addEventListener("click", function (event) {
            var target = document.querySelectorAll('.navbar-collapse')[0];
            this.className = (this.className + " open").replace(/ open open/, "");
            target.className = (target.className + " show").replace(/ show show/, "");

            var darker = document.querySelectorAll('.content-darker')[0];
            if (target.classList.contains("show")) {
                darker.classList.add("show");
                setTimeout(function () {
                    var ninit = anime.timeline({
                        easing: 'linear'
                    });
                    ninit.add({
                        targets: '.bg-collapse svg .path1',
                        translateY: ['-1000px', '0px'],
                        easing: 'easeInOutSine',
                        duration: 500
                    }, '+=0');
                    ninit.add({
                        targets: '.navbar-collapse li',
                        translateX: 0,
                        opacity: 1,
                        easing: 'easeOutQuad',
                        duration: 300,
                        delay: anime.stagger(60)
                    });
                }, 50);
            } else {
                var ninit = anime.timeline({
                    easing: 'linear'
                });
                ninit.add({
                    targets: '.navbar-collapse li',
                    translateX: 200,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
                ninit.add({
                    targets: '.bg-collapse svg .path1',
                    translateY: ['0px', '-1000px'],
                    easing: 'easeInOutSine',
                    duration: 400
                }, '+=0');


                darker.classList.remove("show");
            }


            darker.addEventListener("click", function (event) {
                var target = document.querySelectorAll('.navbar-collapse')[0];
                var toggler = document.querySelectorAll('.navbar-toggler')[0];
                toggler.classList.remove('open');
                target.classList.remove('show');

                var darker = document.querySelectorAll('.content-darker')[0];
                anime({
                    targets: '.navbar-collapse .line',
                    height: "calc(0%)",
                    easing: 'easeOutQuad',
                    duration: 10
                });
                anime({
                    targets: '.navbar-collapse li',
                    translateX: 200,
                    opacity: 0,
                    duration: 100,
                    delay: anime.stagger(0)
                });
                darker.classList.remove("show");

            });
        });
    }

    // Wait for Images
    imagesLoaded(document.querySelector("main"), function (instance) {


        if (document.querySelectorAll(".glide-banner").length > 0) {
            var mybanner = document.getElementById("mybanner");
            if (mybanner) {
                if (mybanner.clientHeight + 260 > y)
                    scrollI.style.opacity = 1;
            }


            var scount = document.querySelectorAll(".glide__slide").length;
            var track = document.querySelectorAll(".glide__track")[0];
            console.log(scount)
            var start = Math.ceil(scount * Math.random()) - 1;
            console.log(start)
            if (start < 0)
                start = 0;
            var glide = new Glide('.glide-banner', {
                type: 'slider',
                startAt: start,
                hoverpause: false,
                autoplay: 10000
            }).mount()

            track.addEventListener("click", function (event) {
                glide.go('>');
            })


            glide.on('run.before', function () {
                console.log('before')
                // Handler logic ...
                var tlinit = anime.timeline({
                    easing: 'linear'
                });
                tlinit.add({
                    targets: '.claim-left-single',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 100
                }, '+=0');
                tlinit.add({
                    targets: '.claim-right-single',
                    opacity: 0,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 100
                }, '+=0');
            })

            glide.on('run.after', function () {
                console.log('after')
                // Handler logic ...
                var tlinit = anime.timeline({
                    easing: 'linear'
                });
                tlinit.add({
                    targets: '.claim-left-single',
                    opacity: 1,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 450
                }, '+=1200');
                tlinit.add({
                    targets: '.claim-right-single',
                    opacity: 1,
                    marginLeft: '0px',
                    easing: 'easeInOutSine',
                    duration: 350
                }, '+=200');
            })
        }
        // fadein main
        var tlinit = anime.timeline({
            easing: 'linear'
        });

        tlinit.add({
            targets: '.lds-dual-ring',
            opacity: 0,
            easing: 'easeInOutSine',
            duration: 50
        }, '+=0');
        tlinit.add({
            targets: '.navbar',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 250
        }, '+=0');
        tlinit.add({
            targets: 'main',
            opacity: 1,
            easing: 'easeInOutSine',
            duration: 550
        }, '+=200');
        tlinit.add({
            targets: '.claim-left',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 450
        }, '+=0');
        tlinit.add({
            targets: '.claim-right',
            opacity: 1,
            marginLeft: '0px',
            easing: 'easeInOutSine',
            duration: 350
        }, '+=200');

        anime({
            targets: '.scroll-circle',
            loop: true,
            translateY: 5,
            easing: 'easeInOutSine',
            duration: 650
        });
    });


})();

var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
            params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

// execute above function
initPhotoSwipeFromDOM('.my-gallery');
// navbar shrink
window.addEventListener("scroll", function () {
    var nav = document.getElementById("mainNav");

    if (window.scrollY > 140) {
        nav.classList.add("shrink");
        if (scrollI)
            scrollI.classList.add("hide");
    } else {
        nav.classList.remove("shrink");
    }

    if (document.querySelectorAll(".scroll-top").length > 0) {
        if (window.scrollY > 240) {
            document.querySelectorAll('.scroll-top')[0].classList.add('show');
        } else {
            document.querySelectorAll('.scroll-top')[0].classList.remove('show');
        }
    }
});

// browser-warning
if (ua.browser.family === "IE") {
    const warning = document.querySelectorAll('.browser-warning');
    warning[0].className = 'browser-warning show';
}
